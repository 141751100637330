<script>
import {VTreeview} from 'vuetify/labs/VTreeview'
import {useCartStore} from '@/js/stores/cart.js'
import {useInternalStore} from "@/js/stores/internal.js";
import CatalogBreadcrumbs from "@/js/components/catalog-breadcrumbs.vue";
import api from "@/js/api.js";
import OrderSidebar from "@/js/components/order-sidebar.vue";
import EditProductModal from "@/js/components/edit-product-modal.vue";
import ProductList from "@/js/components/product-list.vue";
import {tr} from "vuetify/locale";
import Cookies from "js-cookie";
import MobileAddToCard from "@/js/components/mobile-add-to-card.vue";

export default {
    name: "OrderView",
    components: {MobileAddToCard, ProductList, EditProductModal, OrderSidebar, CatalogBreadcrumbs, VTreeview},
    setup() {
        const cartStore = useCartStore();
        const internalStore = useInternalStore();
        return {cartStore, internalStore};
    },
    props: {
        isEditMode: {
            type: Boolean,
            required: true,
        },
        id: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            search_string: '',
            search_loading: false,
            search_products: [],
            products: [],
            category_sidebar_open: this.internalStore.show_catalog,
            categories: [],
            selectedCategory: [],
            order: {},
            isEditData: false,
            statusesEdit: [],
            statuses: [],
            showConfirmDialog: false,
            hasUnsavedChanges: false,
            nextRoute: null,
            initState: false,
            firstIteration: true
        }
    },
    watch: {
        selectedCategory(val) {
            if (val[0]) {
                let routerName = this.isEditMode ? 'edit-order-products' : 'create-order-products'
                this.$router.push({name: routerName, params: {slug: val[0]}});
            }
        },
    },
    created() {
        let token = Cookies.get('role');
        let role = window.types.items.filter((i) => i.id === token)[0];
        this.statuses = role.statuses;
        this.statusesEdit = role.statusesEdit;
        this.clearCart();
        if (!this.isEditMode) {

        } else {
            this.getOrderByNumber(this.$route.params.id);
        }
        this.getProducts();
        this.$nextTick(() => {

                this.unsubscribe = this.cartStore.$subscribe((mutation, state) => {
                    if (this.firstIteration) {
                        this.initState = JSON.stringify(this.cartStore.$state);
                        this.firstIteration = false;
                    } else{
                        const currentState = JSON.stringify(state);
                        this.hasUnsavedChanges = this.initState !== currentState;
                    }
                });

        });
    },

    mounted() {
        if (this.$route.params.id && this.isEditMode) {
            this.openOrder(true)
        }
    },

    methods: {
        orderSaved(){
            this.hasUnsavedChanges = false;
        },
        async getOrderByNumber(numOrder) {
            try {
                let response = await api.getOrderByNumber(numOrder);
                this.order = response.data.data;
                this.isEditData = this.statusesEdit.indexOf(this.order.status) > -1
            } catch (error) {
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Get order error'
                })
            }
        },
        openOrder(flag) {
            this.$mitt.emit('show_order', flag)
            this.$mitt.emit('close-mobile-add')
        },
        async getProducts() {
            try {
                let response = await api.getProducts();
                this.products = {categories: response.data.data.items};
                this.categories = this.products.categories.map((e) => (
                    {
                        slug: e.slug,
                        title: e.name
                    })
                );
                if (this.$route.params.slug) {
                    this.selectedCategory =
                        this.products.categories.filter((cc) => cc.slug === this.$route.params.slug).map((e) => (
                            e.slug)
                        );
                }
            } catch (error) {
                this.$mitt.emit('show_msg', {
                    msg: 'Get categories error'
                })
            }
        },
        confirmLeave(leave) {
            if (leave) {
                this.hasUnsavedChanges = false;
                this.$router.push(this.nextRoute);
            }
            this.showConfirmDialog = false;
        },
        search(val) {
            if (this.search_string == val) {
                return false;
            }
            this.search_string = val;
            this.search_loading = true;
            try {
                this.search_products = this.products?.categories.reduce((p = [], cat) => {
                    return p.products ? [...p.products, ...cat.products] : [...p, ...cat.products];
                }).filter((p) => p.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
            } catch (error) {
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Search error'
                })

            }

            setTimeout(() => {
                this.search_loading = false;
            }, 300)

        },

        addToCard(product) {
            this.cartStore.addProductToCart(product)
        },
        clearCart() {
            this.cartStore.clear();
        },
        selectFromTree(data) {
            console.log(data)
        },
        clearSearch(event) {
            this.search_products = [];
            this.search_string = '';
            document.activeElement.blur();
        },


    },
    beforeRouteLeave(to, from, next) {
        if (this.hasUnsavedChanges) {
            this.showConfirmDialog = true;
            this.nextRoute = to.fullPath;
        } else {
            next();
        }
    },
    beforeDestroy() {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    },

}
</script>

<template>
    <v-container height="100%" fluid class="order__page position-relative">
        <order-sidebar :isEditMode="isEditMode" :order="order" :isEditData="isEditData" @order-saved="orderSaved"/>
        <v-container class="grey-lighten-3 position-relative">

            <!--            Пока закоментил, это молалка редактирования товара -->
            <!--            <edit-product-modal/>-->

            <div class="page__header">
                {{
                    isEditMode ? `Редагування замовлення №${$route?.params?.id || null}` + ' (' + order.status + ')' : 'Нове замовлення'
                }}
            </div>
            <div class="catalog__btn">
                <v-btn icon="list"
                       @click="internalStore.toggleCatalog"
                >
                </v-btn>
            </div>
            <catalog-breadcrumbs :isEdit="isEditMode"></catalog-breadcrumbs>
            <v-row class="mt-0 pt-0 sticky__row mb-0">
                <v-col
                    class="pt-0"
                    cols="12"
                >
                    <v-text-field
                        class="search__field"
                        prepend-inner-icon="search"
                        ref="search_field"
                        v-debounce:300="search"
                        variant="underlined"
                        :loading="search_loading"
                        :model-value="search_string"
                        label="Пошук товарів"
                        hide-details
                        @keyup.enter="$event.target.blur()"
                        :clearable="true"
                        @click:clear="clearSearch($event)"
                    ></v-text-field>
                </v-col>
            </v-row>
            <template v-if="search_string?.length>0">
                <product-list :products_list="search_products"></product-list>
            </template>
            <template v-else>
                <!--                CATEGORY ROUTER VIEW-->
                <router-view :isEditMode="isEditMode" :isEditData="isEditData" :products="products"></router-view>
            </template>


        </v-container>
        <v-navigation-drawer
            v-model="internalStore.show_catalog"
            :mobile-breakpoint="'lg'"
            location="right"
            width="220"
            app
            class="">
            <template v-slot:prepend>
                <div class="mt-1 d-lg-none d-flex justify-end px-2">
                    <v-btn variant="text" position="absolute" class="top-1 right-1 " style="z-index: 4" size="small"
                           icon="close" @click="internalStore.show_catalog = false">

                    </v-btn>
                </div>
            </template>
            <v-treeview
                :items="categories"
                activatable
                open-on-click
                item-title="title"
                item-value="slug"
                v-model:activated="selectedCategory"
                @click:select="selectFromTree"
            >
            </v-treeview>
        </v-navigation-drawer>
        <MobileAddToCard/>
    </v-container>
    <v-fab
        class="fixed__btn"
        icon="shopping_cart"
        :absolute="false"
        width="50px"
        height="50px"
        @click="openOrder(true)"
    ></v-fab>
    <v-dialog v-model="showConfirmDialog" persistent max-width="500">
        <v-card>
            <v-card-title>Незбережені зміни</v-card-title>
            <v-card-text>
                Ви маєте незбережені зміни. Ви впевнені, що хочете залишити сторінку?
            </v-card-text>
            <v-card-actions>
                <v-btn text color="green" @click="confirmLeave(false)">Залишитись</v-btn>
                <v-btn text color="red" @click="confirmLeave(true)">Вийти</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss">
.order__page {
    position: relative;

    .catalog__btn {
        position: absolute;
        top: 10px;
        right: 15px;
        z-index: 5;
        cursor: pointer;
    }
}

.sticky__row {
    background-color: rgb(238, 238, 238);
    position: sticky;
    top: 63px;
    z-index: 4;
}

.fixed__btn {
    position: fixed;
    bottom: 55px;
    right: 75px;
}

.page__header {
    font-size: 18px;

}

.search__table {
    tbody {
        tr:nth-child(odd) {
            background-color: #f2f2f2;
        }
    }
}

.product__list-col {
    background-color: #dfe4f3;
}

.order__page {
    padding: 0;
}

.card__title {
    font-size: 14px;
}

.content:has(.order__page) {
    background-color: rgb(238, 238, 238);
}

.sidebar__table {
    thead {
        th {
            white-space: nowrap;
        }

    }

    .name {
        width: 60%;
    }

    .count {
        min-width: 60px;
        text-align: center;
    }

    .price {
        text-align: right;
    }

    .total {
        width: 60px;
        text-align: right;
    }

    .cart__row {
        cursor: pointer;
        @media (hover: hover) {
            &:hover {
                background-color: #efefef;
            }
        }
    }

}

@media screen and (min-width: 960px) {
    .fixed__btn {
        display: none;
    }
}

</style>
