<script>
import api from "../api.js";
import TopBar from "@/js/components/top-bar.vue";
import CheckPermission from "@/js/components/check-permission.vue";
import Cookies from 'js-cookie';
import {useInternalStore} from "../stores/internal.js";

export default {
    name: "App",
    components: {CheckPermission, TopBar},
    setup(){
        const internalStore = useInternalStore();
        return {internalStore};
    },
    data() {
        return {
            userAuthorized: false,
            isAdmin:false,
            loading: false,
            userRoles: [],
            role:null
        }
    },
    watch:{
        role(newValue, oldValue) {
           this.internalStore.setUserRole(newValue)
        },
    },

    mounted() {
        // КОСТИЛЬ ДЛЯ ТОГО ЩОБ ЗАКРИВАЛАСЬ КЛАВІАТУРА В САФАРІ
        document.addEventListener('click', ($event) => {
            if ($event.target.classList.contains('qty__input') && $event.target.closest('.search__field')) {
                document.activeElement.blur();
            }
        })
        this.$mitt.on('show-global-loader', (flag) => {
            this.globalLoaderToggle(flag)
        })
        this.$mitt.on('check-user-permissions', () => {
            this.checkUserPermissions();
        })
        this.$mitt.on('user-logout', () => {
            Cookies.remove('token',null);
            Cookies.remove('role',null);
            console.log('User logged out')
            this.internalStore.resetUserRole();
            this.checkUserPermissions()
        })
        this.$mitt.on('user-change-role', (v) => {
            this.setRoles(v);
        })
        //this.checkUserPermissions();
    },
    created() {
        this.checkUserPermissions();
    },
    methods: {
        checkUserPermissions() {
            this.loading = true;
            //setTimeout(() => {
                let token = Cookies.get('token');
                let that = this;
                this.isAdmin = false;
                this.userRoles = [];
                function setOk(r){
                    that.userAuthorized = r;
                    setTimeout(() => {
                        that.$mitt.emit('user-authorization', r);
                    })
                    that.loading = false;
                    return r;
                }
                if (!token || token.length<=0 || !window.types){
                    return setOk(false);
                }
                if (window.types.admin === token){
                    //admin
                    this.isAdmin = true;
                    this.userRoles = window.types.items;
                    this.setRoles();
                    return setOk(true);
                }
                let type = window.types.items.filter((t)=>t.code === token);
                if (type.length){
                    this.userRoles = type;
                    this.setRoles();
                    return setOk(true);
                }
                setOk(false);
            //})

        },
        setRoles(v){
            let role = Cookies.get('role');
            if (v && this.userRoles.filter((r)=>r.id==v).length){
                Cookies.set('role', v, {expires: 120});
                document.location.reload();
                return true;
            }
            if (!role){
                role = this.userRoles[0].id;
                Cookies.set('role', role, {expires: 120});
            }
            this.role = role
        },
        globalLoaderToggle(flag) {
            this.loading = flag;
        }
    }
}
</script>

<template>
    <v-app>
        <top-bar :user-roles="userRoles" :role="role" :isAdmin="isAdmin" />
        <v-main class="content" style="min-height: 300px;">
            <router-view/>
        </v-main>
        <CheckPermission :user_roles="userRoles"/>
        <v-overlay v-model="loading"
                   persistent
                   class="align-center justify-center loader__overlay"
        >
            <v-progress-circular
                color="primary"
                size="64"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<style lang="scss">
.v-bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}

.v-input {
    &.v-input--error {
        .v-input__details {
            display: block;
        }
    }

    .v-input__details {
        display: none;
    }
}
.loader__overlay{
    .v-overlay__scrim{
        backdrop-filter: blur(10px);
        background: rgba(255, 255, 255, 0.3);
        opacity:1;
    }

}
</style>
