<script>
import {useCartStore} from "../stores/cart.js";
import api from "@/js/api.js";
import {useInternalStore} from "../stores/internal.js";

export default {
    name: "order-sidebar",
    props: ["isEditMode", "order", "isEditData"],
    setup() {
        const cartStore = useCartStore();
        const internalStore = useInternalStore();
        return {cartStore, internalStore};
    },
    data() {
        return {
            open_order: window.innerWidth > 960,
            show_date: false,
            rules: {
                required: value => !!value || 'Поле обов\'язкове'
            },
            numberValue: '',
            expandedIndex: null,
            showRemoveDialog: false,
            selectedClient: null,
            clients: [],
            searchClientInput: "",
            keys: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
            valid: false,
            model: {
                uid: null,
                number: null,
                create_at: null,
                update_at: null,
                date: null,
                client: null,
                status: "Новий",
                products: this.cartStore.products,
                sum: this.cartStore.getCartTotalSum
            },
            dateLimitValue: this.dateLimit(),
            keyboardVisible: false

        }
    },
    created() {
        this.getClients();
        this.$mitt.on('show_order', this.orderToggle);
        this.$mitt.on('save_order', this.saveOrder);
        if (this.isEditMode) {
            if (this.order && this.order.uid) {
                this.setModelOrder()
            } else {
                const unwatch = this.$watch('order', () => {
                    this.setModelOrder()
                    unwatch()
                })
            }
        } else {
            const nextDay = new Date();
            nextDay.setDate(nextDay.getDate() + 1);
            this.model.date = nextDay;
        }

    },
    computed: {
        productsInCart() {
            //this.model.products=
            return this.cartStore.products;
        },
        computedDateFormatted() {
            return this.formatDate(this.model.date)
        },
    },
    methods: {
        setDate(data) {
            this.show_date = false;
        },
        dateLimit() {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            return yesterday;

        },
        async printOrder(order) {
            this.$mitt.emit('show-global-loader', true);
            let response = await api.pdfOrder(this.model.number);
            let pdfData = response.data.data;
            this.$mitt.emit('show-global-loader', false);
            this.printCustomContent(pdfData)
        },
        printCustomContent(pdfData) {
            // Створюємо нове вікно
            var printWindow = window.open('', '', 'height=600,width=800');
            // Додаємо HTML-вміст, який хочемо надрукувати
            printWindow.document.write('<html><head><title>Друк</title>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(`<iframe src="${pdfData}" width="100%" height="100%"></iframe>`);
            printWindow.document.write('</body></html>');
        },
        setModelOrder() {
            let m = Object.assign({}, this.order);
            if (m.date) {
                let dayOne = (m.date.split(" "))[0].split(".");
                m.date = new Date(dayOne[2], +dayOne[1] - 1, dayOne[0]);
            }
            m.products = this.cartStore.assignProduct(m.products);
            this.model = m;
        },
        async saveOrder() {
            this.$mitt.emit('show-global-loader', true);
            let model = Object.assign({}, this.model);
            model.sum = this.cartStore.getCartTotalSum;
            model.date = this.formatDate(this.model.date) + ' 00:00:00';
            let response = await api.saveOrder(model);
            if (response.data && response.data.status && response.data.status === true) {
                this.$emit('orderSaved');
                this.$router.push({name: 'orders-list'})
                this.cartStore.clear();
            }
            this.$mitt.emit('show-global-loader', false);
        },
        async deleteOrder() {
            this.$mitt.emit('show-global-loader', true);
            let response = await api.deleteOrder(this.model.number);
            if (response.data && response.data.status && response.data.status === true) {
                this.cartStore.clear();
                this.$router.push({name: 'orders-list'})
            }
            this.$mitt.emit('show-global-loader', false);
        },
        async getClients() {
            try {
                let response = await api.getClients();
                this.clients = response.data.data.items;

            } catch (error) {
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Get categories error'
                })
            }
        },
        appendKey(item, key) {
            if (this.keyboardVisible) {
                item.count = '';
                this.keyboardVisible = false;
            }
            if (item.count.toString() === '0' && item.count.toString() !== '.') {
                item.count = key;
            } else {
                item.count += key;
            }

        },
        appendDotKey(item, key) {
            if (item.count && !((item.count).toString()).includes('.')) {
                item.count += '.';
            }
        },
        deleteLastKey(item) {
            item.count = item.count.toString().slice(0, -1);
        },
        deleteQty(item) {
            item.count = '';
        },
        openKeyboard(item) {
            this.keyboardVisible = true;

        },
        closeKeyboard(item) {
            if ((item.count).toString().endsWith('.')) {
                item.count = item.count.slice(0, -1);
            }
            if ((item.count).toString().startsWith('.')) {
                item.count = item.count.slice(0, -1);
            }

            if (!item.count || +item.count < +item.product.steps) item.count = item.product.steps;
            this.keyboardVisible = false;
        },
        customFilter(item, queryText, itemText) {
            const searchText = queryText.toLowerCase();
            const itemName = itemText.toLowerCase();
            return itemName.includes(searchText);
        },
        toggleDetails(index) {
            this.expandedIndex = this.expandedIndex === index ? null : index;
        },
        orderToggle(flag) {
            this.open_order = flag;
        },
        // Функція для форматування дати у форматі дд-мм-рррр
        formatDate(date) {
            if (!date) return null;
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Місяці від 0 до 11
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        },
        clearDate() {
            event.stopPropagation();
            this.model.date = null;
        },
        handleInput(product) {
            // Перевірка, щоб увести тільки числа
            const value = event.target.value;
            if (!isNaN(value) && value !== '') {
                this.cartStore.changeProductQty('manual', product, event.target.value)
            } else {
                // Видаляємо некоректне введення
                event.target.value = this.numberValue;
            }

        },
        handleKeydown(event) {
            // Дозволяємо тільки числові клавіші та деякі спеціальні клавіші (Backspace, Delete, Arrow keys)
            const key = event.key;
            if (!/[0-9]/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key)) {
                event.preventDefault();
            }

        },
        handleBlur(product) {
            // Якщо поле порожнє, відновлюємо попереднє значення
            if (event.target.value === '') {
                this.cartStore.changeProductQty('manual', product, 1)
            }
        },
        removeItem(item, isActive) {
            setTimeout(() => {
                this.cartStore.removeProduct(item);
                isActive.value = false;
            })


        }
        // Метод для показа модалки редактиварония
        // showProductOptions(product) {
        //     this.$mitt.emit('show_product_option', product)
        // },
    }

}
</script>

<template>
    <v-navigation-drawer
        v-model="open_order"
        :mobile-breakpoint="'md'"
        width="550"
        class="flex product__cart">

        <v-table class="sidebar__table">
            <thead class="hidden-sm-and-down">
            <tr>
                <th class="font-weight-bold name">
                    Найменування
                </th>
                <th class="font-weight-bold count">
                    К-сть
                </th>
                <th class="font-weight-bold price">
                    Ціна
                </th>
                <th class="font-weight-bold total ">
                    Всього
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item, index) in productsInCart"
                      :key="item.uid">
                <tr class="hidden-md-and-up mobile__prod-name">
                    <td class="cart__item-name" colspan="5">
                        <div @click="toggleDetails(index)" class="name__comment-wrapper">
                            <div class="name__wrapper">
                                <div class="name_title">
                                    <v-icon size="12" color="primary" class="hidden-md-and-up">fiber_manual_record
                                    </v-icon>
                                    {{ item.product?.name }}
                                </div>
                                <div class="comment" v-if="cartStore.checkIfHasComment(item.uid)">
                                    {{ item?.comment || '' }}
                                </div>
                            </div>
                            <v-icon>
                                {{
                                    expandedIndex === index ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                }}
                            </v-icon>

                        </div>
                    </td>
                </tr>
                <tr

                    class="cart__row"
                >
                    <td class="hidden-sm-and-down cart__item-name">
                        <div @click="toggleDetails(index)" class="name__comment-wrapper">
                            <div class="name__wrapper">
                                <div class="name_title">

                                    {{ item.product?.name }}
                                </div>
                                <div class="comment" v-if="cartStore.checkIfHasComment(item.uid)">
                                    {{ item?.comment || '' }}
                                </div>
                            </div>
                            <v-icon>
                                {{
                                    expandedIndex === index ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                                }}
                            </v-icon>

                        </div>
                    </td>
                    <td>
                        <div class="qty__wrapper">
                            <v-btn icon="remove" size="x-small"
                                   @click="cartStore.changeProductQty('minus', item)"></v-btn>
                            <v-menu
                                location="bottom center"
                                :close-on-content-click="false"
                                @click:outside="closeKeyboard(item)"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        variant="text"
                                        size="small"
                                        v-bind="props"
                                        style="padding: 0; max-width: 50px; overflow-x: hidden"
                                        @click="openKeyboard(item)"
                                    >
                                        {{ item.count }}
                                    </v-btn>
                                </template>
                                <v-card class="num__keyboard-card">
                                    <div class="num__keyboard-wrapper">
                                        <v-btn v-for="key in keys" :key="key" @click="appendKey(item,key)">{{
                                                key
                                            }}
                                        </v-btn>
                                        <v-btn @click="appendDotKey(item,'.')" v-if="item.product.is_floating">.</v-btn>
                                        <v-btn @click="deleteLastKey(item)">
                                            <v-icon icon="backspace">
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-menu>

                            <v-btn icon="add" size="x-small" @click="cartStore.changeProductQty('plus', item)"></v-btn>
                        </div>
                    </td>
                    <td>{{ item.product?.price }}</td>
                    <td>{{ (item.getSum()).toFixed(2) }}</td>
                    <td class="d-flex justify-end align-center">
                        <v-dialog
                            max-width="400"
                            persistent
                            z-index="99999999999"
                            app
                        >
                            <template v-slot:activator="{ props: activatorProps }">
                                <v-btn
                                    v-bind="activatorProps"
                                    icon="delete"
                                    variant="text"
                                    color="red"
                                    size="x-small"
                                >
                                </v-btn>
                            </template>
                            <template v-slot:default="{isActive}">
                                <v-card
                                    prepend-icon="info"
                                    title="Видалити?"
                                    :text="item?.product.name || ''"
                                >
                                    <template v-slot:actions>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="isActive.value = false">
                                            Відмінити
                                        </v-btn>

                                        <v-btn
                                            variant="tonal"
                                            @click="removeItem(item, isActive)">
                                            Так
                                        </v-btn>
                                    </template>
                                </v-card>
                            </template>
                        </v-dialog>
                    </td>
                </tr>
                <tr v-if="expandedIndex === index">
                    <td colspan="5">
                        <v-text-field
                            class="mb-2"
                            label="Коментар"
                            variant="underlined"
                            :focused="item?.comment ? item.comment.length>0:false"
                            :model-value="item?.comment || ''"
                            hide-details
                            @blur="expandedIndex = null"
                            @input="cartStore.addProductComment(item, $event.target.value)"
                        >
                        </v-text-field>
                    </td>
                </tr>
            </template>
            </tbody>
            <template v-if="productsInCart.length<=0" v-slot:bottom>
                <p style="text-align: center" class="text-red text-xl-h5 pa-3">Оберіть товари</p>
            </template>
        </v-table>
        <template v-slot:append>
            <v-form v-model="valid">
                <v-row class="px-3 pt-3">
                    <v-col
                        cols="6"
                        class=" font-weight-bold"
                    >
                        Всього
                    </v-col>
                    <v-col
                        cols="6"
                        class="text-end font-weight-bold"
                    >
                        {{ cartStore.getCartTotalSum }} ₴
                    </v-col>
                </v-row>
                <v-row class="px-3">
                    <v-col
                        cols="12">
                        <v-autocomplete
                            v-model="model.client"
                            :items="clients"
                            item-title="name"
                            item-value="uid"
                            :label="internalStore.$state.role == 'type1'? 'Постачальник':'Клієнт'"
                            clearable
                            density="comfortable"
                            variant="underlined"
                            return-object
                            :search-input.sync="searchClientInput"
                            :filter="customFilter"
                            :menu-props="{ location: 'top', offset: 15 }"
                            :rules="[rules.required]"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row class="px-3">
                    <v-col
                        cols="12"
                    >
                        <v-menu
                            v-model="show_date"
                            :close-on-content-click="false"
                            location="bottom"
                            offset="15"
                        >
                            <template v-slot:activator="{ props }">
                                <v-text-field
                                    v-model="computedDateFormatted"
                                    label="На дату:"
                                    prepend-inner-icon="event"
                                    :append-inner-icon="model.date? 'close': ''"
                                    @click:appendInner="clearDate()"
                                    readonly
                                    :rules="[rules.required]"
                                    v-bind="props"
                                    variant="underlined"
                                    hide-details
                                >
                                </v-text-field>
                            </template>
                            <v-date-picker
                                v-model="model.date"
                                hide-header
                                @update:modelValue="setDate"
                                :min="dateLimitValue"
                            >
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row class="px-3 pb-3">
                    <v-col
                        sm="2"
                        xs="3"

                    >
                        <v-menu
                            :close-on-content-click="false"
                            location="top"


                            offset="10"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    color="primary"
                                    v-bind="props"
                                    width="100%"
                                >
                                    <v-icon icon="more_horiz">

                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list>
                                    <v-list-item>

                                        <v-dialog
                                            max-width="400"
                                            persistent
                                            z-index="99999999999"
                                            app
                                        >
                                            <template v-slot:activator="{ props: activatorProps }">
                                                <v-btn
                                                    v-bind="activatorProps"
                                                    width="100%"
                                                    class="tex"
                                                    prepend-icon="delete"
                                                    :disabled="!isEditData"

                                                >
                                                    Видалити
                                                </v-btn>
                                            </template>
                                            <template v-slot:default="{isActive}">
                                                <v-card
                                                    prepend-icon="info"
                                                    title="Видалити замовлення?"
                                                >
                                                    <template v-slot:actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn @click="isActive.value = false">
                                                            Відмінити
                                                        </v-btn>
                                                        <v-btn
                                                            variant="tonal"
                                                            @click="deleteOrder()"
                                                        >
                                                            Так
                                                        </v-btn>
                                                    </template>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-list-item>
                                </v-list>
                            </v-card>

                        </v-menu>
                    </v-col>
                    <v-col
                        sm="2"
                        xs="3"
                    >
                        <v-btn
                            width="100%"
                            class="tex"
                            :disabled="!isEditMode"
                            @click="printOrder()"
                        >
                            <v-icon icon="print"></v-icon>
                        </v-btn>
                    </v-col>
                    <v-spacer class="spacer"></v-spacer>
                    <v-col
                        sm="4"
                        xs="3"
                    >
                        <v-btn
                            type="button"
                            width="100%"
                            class="font-weight-bold"
                            color="green-darken-1"
                            @click="saveOrder()"
                            :disabled="!valid||cartStore.products.length<=0||(!isEditData&&isEditMode)"
                        >
                            <template v-if="$route.params.id">
                                Зберегти
                            </template>
                            <template v-else>
                                {{ internalStore.$state.role === 'type2' ? 'В роботу' : 'Створити' }}
                            </template>
                        </v-btn>


                    </v-col>
                    <v-col
                        class="close__sidebar"
                        xs="3"
                        sm="2"
                    >
                        <v-btn
                            width="100%"
                            @click="open_order = false"
                        >
                            <!--                            <v-icon icon="close"></v-icon>-->
                            <b>Каталог</b>
                        </v-btn>
                    </v-col>

                </v-row>
            </v-form>
        </template>
    </v-navigation-drawer>

</template>

<style lang="scss">
.product__cart {
    .v-table > .v-table__wrapper > table > tbody > tr > td, .v-table > .v-table__wrapper > table > tbody > tr > th, .v-table > .v-table__wrapper > table > thead > tr > td, .v-table > .v-table__wrapper > table > thead > tr > th, .v-table > .v-table__wrapper > table > tfoot > tr > td, .v-table > .v-table__wrapper > table > tfoot > tr > th {
        padding: 0 7px;
    }

}

.name__comment-wrapper {
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-gap: 5px;
    align-items: center;

    .name__wrapper {
        .name_title {
            font-size: 14px;
            line-height: 16px;
        }
    }

    .comment {
        font-size: 12px;
        line-height: 14px;
        color: grey;
    }
}

.comment__budge {
    &.v-badge--dot .v-badge__badge {
        width: 5px;
        height: 5px;
    }
}

.close__sidebar {
    display: none;
}

.num__keyboard-card {
    padding: 10px;

    .num__keyboard-wrapper {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(3, 1fr);
    }
}

.qty__wrapper {
    display: inline-flex;
    align-items: center;
    width: fit-content;
    gap: 5px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    input {
        width: 40px;
        text-align: center;
        padding: 0 5px;
    }
}

@media screen and (max-width: 960px) {
    .close__sidebar {
        display: flex;
    }
    .product__cart {
        width: 100% !important;
        max-width: 100% !important;
        transform: translateX(-100%) !important;

        &.v-navigation-drawer--active {
            transform: translateX(0) !important;
        }
    }
    .cart__item-name {

    }
    .name__comment-wrapper {
        .name__wrapper {

            .name_title {
                font-weight: 700;
                background-color: rgba(0, 0, 0, 0.05);
                width: fit-content;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .spacer {
        display: none;
    }
    .mobile__prod-name {
        td {
            border-bottom: none !important;;
        }
    }
}
</style>
