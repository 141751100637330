<script>
import {useCartStore} from "@/js/stores/cart.js";

export default {
    name: "MobileAddToCard",
    setup() {
        const cartStore = useCartStore();
        return {cartStore};
    },
    data() {
        return {
            bottomSheet: false,
            product: null,
            keys: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
            count:0,
            dialog:false
        };
    },
    mounted() {
        this.$mitt.on('show_mobile_qty', this.showBottomSheet);
        this.$mitt.on('close-mobile-add', ()=>{
            this.bottomSheet = false;
        });

    },

    methods: {
        showBottomSheet(uid) {
            this.product = this.cartStore.getProduct(uid);
            this.bottomSheet = true;

        },
        addToCart() {
            // this.$mitt.emit('show_order', true)
            this.bottomSheet = false;
        },
        minusQty(product){
            if(product.count>1){
                this.cartStore.changeProductQty('minus', product)
            } else{
                this.removeFromCart()
            }
        },
        appendKey(item, key) {
            if (this.keyboardVisible) {
                item.count = '';
                this.keyboardVisible = false;
            }
            if (item.count.toString() === '0' && item.count.toString() !== '.') {
                item.count = key;
            } else {
                item.count += key;
            }

        },
        appendDotKey(item, key) {
            if (item.count && !((item.count).toString()).includes('.')) {
                item.count += '.';
            }
        },
        deleteLastKey(item) {
            item.count = item.count.toString().slice(0, -1);
        },
        deleteQty(item) {
            item.count = '';
        },
        openKeyboard(item) {
            this.keyboardVisible = true;

        },
        closeKeyboard(item) {
            if ((item.count).toString().endsWith('.')) {
                item.count = item.count.slice(0, -1);
            }
            if ((item.count).toString().startsWith('.')) {
                item.count = item.count.slice(0, -1);
            }

            if (!item.count || +item.count < +item.product.steps) item.count = item.product.steps;
            this.keyboardVisible = false;
        },
        removeFromCart(){
            this.dialog = true;

        },
        removeItem(){
            this.dialog = false;
            this.bottomSheet = false;
            this.cartStore.removeProduct(this.product);
        }
    },
}
</script>
<template>
    <v-bottom-sheet
        :close-on-back="false"
        persistent
        :scrim="false"
        v-model="bottomSheet"
    class="mobile__catalog-count"
    >
        <v-card class="position-relative">
            <v-btn icon="close" variant="text" size="x-small" class="position-absolute right-0 top-0"
                   @click="bottomSheet = false"></v-btn>
            <v-card-title class="text-body-1">{{ product.product.name }}</v-card-title>
            <v-card-text class="justify-space-between d-flex align-center">
                <div class="qty__wrapper">
                    <v-btn icon="remove" size="x-small"
                           @click="minusQty(product)"></v-btn>
                    <v-menu
                        location="bottom center"
                        :close-on-content-click="false"
                        @click:outside="closeKeyboard(product)"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                variant="text"
                                size="small"
                                v-bind="props"
                                style="padding: 0; max-width: 50px; overflow-x: hidden"
                                @click="openKeyboard(product)"
                            >
                                {{ product.count }}
                            </v-btn>
                        </template>
                        <v-card class="num__keyboard-card">
                            <div class="num__keyboard-wrapper">
                                <v-btn v-for="key in keys" :key="key" @click="appendKey(product,key)">{{
                                        key
                                    }}
                                </v-btn>
                                <v-btn @click="appendDotKey(product,'.')" v-if="product.product.is_floating">.</v-btn>
                                <v-btn @click="deleteLastKey(product)">
                                    <v-icon icon="backspace">
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-card>
                    </v-menu>

                    <v-btn icon="add" size="x-small" @click="cartStore.changeProductQty('plus', product)"></v-btn>
                </div>
                <div class="product__price d-flex ga-7">
                    <div class="price__col flex flex-column ga-1">
                        <div class=" font-weight-bold title text-body-2 text-bold">
                            Ціна
                        </div>
                        <div class="price">
                            {{product.product?.price}} грн
                        </div>
                    </div>
                    <div class="price__col flex flex-column ga-1">
                        <div class="font-weight-bold title text-body-2 text-bold">
                            Усього
                        </div>
                        <div class="price">
                            {{(product.getSum()).toFixed(2)}} грн
                        </div>
                    </div>

                </div>
            </v-card-text>
<!--            <v-card-actions>-->
<!--                <v-btn    type="button"-->
<!--                          class="font-weight-bold"-->
<!--                          color="green-darken-1"-->
<!--                          @click="addToCart">Додати</v-btn>-->
<!--                <v-btn color="secondary" @click="removeFromCart">Скасувати</v-btn>-->
<!--            </v-card-actions>-->
            <v-dialog
                max-width="400"
                persistent
                z-index="99999999999"
                app
                v-model="dialog"
            >

                    <v-card
                        prepend-icon="info"
                        title="Видалити?"
                        :text="product?.product.name || ''"
                    >
                        <template v-slot:actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="dialog = false">
                                Відмінити
                            </v-btn>

                            <v-btn
                                variant="tonal"
                                @click="removeItem">
                                Так
                            </v-btn>
                        </template>
                    </v-card>

            </v-dialog>
        </v-card>
    </v-bottom-sheet>
</template>

<style lang="scss">
body:has(.mobile__catalog-count){
    .fixed__btn{
        bottom: 170px;
    }

}
.v-overlay-scroll-blocked:has(.mobile__catalog-count){
    position: initial !important;
    top: initial !important;
    left: initial !important;
    width: initial !important;
    height: initial !important;
}
</style>
