import {defineStore} from 'pinia';

export const useInternalStore = defineStore('internal', {
    state: () => ({
        show_catalog: false,
        role:null,
    }),
    actions:{
        toggleCatalog(){
            this.show_catalog = !this.show_catalog;
        },
        setUserRole(role){
            console.log('storeRole', role)
            this.role = role;
        },
        resetUserRole(){
            this.role = null;
        }
    },
    persist: true
})
